import axios from "axios";
import Cookies from "js-cookie";
import { HttpError } from "@pankod/refine-core";

import { settings } from "../common/settings";
import { COOKIE_OPTIONS } from "../common/constants";

let refreshingFunc: undefined | Promise<any> = undefined;

const logout = () => {
  Cookies.remove(settings.auth.accessToken, COOKIE_OPTIONS);
  Cookies.remove(settings.auth.refreshToken, COOKIE_OPTIONS);
  Cookies.remove(settings.auth.remember, COOKIE_OPTIONS);
  window.location.href = "/login";
};

export const handleUpdateAccessToken = async () => {
  const refreshToken = Cookies.get(settings.auth.refreshToken);
  let response: Response = await fetch(
    `${settings.api.backendUrl}/auth/refresh`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({ refreshToken }),
    }
  );
  if (!response.ok && response.status === 401) {
    return logout();
  }
  const data = await response.json();
  return [data?.accessToken, data?.refreshToken];
};

const isUnauthorizedError = (error: any) => {
  const {
    response: { status },
  } = error;
  return status === 401;
};

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get(settings.auth.accessToken);
  config.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  return {
    ...config,
  };
});

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalConfig = error.config;
    const token = Cookies.get(settings.auth.refreshToken);
    const remember = Cookies?.get(settings.auth.remember) === "true";
    if (!token || !isUnauthorizedError(error)) {
      const customError: HttpError = {
        ...error,
        message: `Ошибка ${error.response?.status || ""}`,
        statusCode: error.response?.status,
      };
      return Promise.reject(customError);
    }

    try {
      if (!refreshingFunc) {
        refreshingFunc = handleUpdateAccessToken();
      }
      const [newAccessToken, newRefreshToken] = await refreshingFunc;
      Cookies.set(
        settings.auth.accessToken,
        newAccessToken,
        remember ? COOKIE_OPTIONS : {}
      );
      Cookies.set(
        settings.auth.refreshToken,
        newRefreshToken,
        remember ? COOKIE_OPTIONS : {}
      );
      originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
      try {
        return await axios.request(originalConfig);
      } catch (innerError) {
        throw innerError;
      }
    } catch (err: any) {
      if (isUnauthorizedError(err)) {
        return logout();
      }
    } finally {
      refreshingFunc = undefined;
    }
  }
);
